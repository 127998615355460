import Vue from 'vue'
import Router from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(Router);

const withPrefix = (prefix, routes) =>
    routes.map((route) => {
        route.path = prefix + route.path;
        return route;
    });

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'home',
            component: Home,
            allowed: [0,1,2,3]
        },
        {
            path: '/search',
            name: 'Search',
            component: () => import('../views/Search'),
            meta: {
                title: 'Leads',
                allowed: [0,1,2,3,4],
            }
        },
        {
            path: '/me',
            name: 'me',
            component: () => import('../views/me/index')
        },
        {
            path: '/logs',
            name: 'Logs',
            component: () => import('../views/logs/index'),
            meta: {
                title: 'Logs',
                allowed: [0,1]
            }
        },
        {
            path: '/help',
            name: 'Help',
            component: () => import('../views/help/index'),
            meta: {
                title: 'Logs',
                allowed: [0,1,2]
            }
        },
        ...withPrefix('/users', [
            {
                path: '/list',
                name: 'user-list',
                component: () => import('@/views/user-managment/list'),
                meta: {
                    title: 'User management',
                    allowed: [0, 1],
                }
            },
            {
                path: '/edit-user/:id',
                name: 'user-edit',
                component: () => import('@/views/user-managment/form'),
                meta: {
                    title: 'User management',
                    allowed: [0, 1],
                }
            },
            {
                path: '/add-user',
                name: 'user-create',
                component: () => import('@/views/user-managment/form'),
                meta: {
                    title: 'User management',
                    allowed: [0, 1],
                }
            }
        ]),
        ...withPrefix('/jobs', [
            {
                path: '/list',
                name: 'job-list',
                component: () => import('@/views/job-managment/list'),
                meta: {
                    title: 'Jobs management',
                    allowed: [0, 1],
                }
            },
            {
                path: '/edit-jobs/:id',
                name: 'job-edit',
                component: () => import('@/views/job-managment/form'),
                meta: {
                    title: 'Jobs management',
                    allowed: [0, 1],
                }
            },
            {
                path: '/add-job',
                name: 'job-create',
                component: () => import('@/views/job-managment/form'),
                meta: {
                    title: 'Jobs management',
                    allowed: [0, 1],
                }
            }
        ]),
        ...withPrefix('/iframe-form', [
            {
                path: '/list',
                name: 'iframe-list',
                component: () => import('@/views/iframe-form/list'),
                meta: {
                    title: 'Leads',
                    allowed: [0,1]
                }
            },

            // Edit enitites
            {
                path: '/edit-funnel/:id',
                name: 'iframe-edit-funnel',
                component: () => import('@/views/iframe-form/form-components/funnel'),
                meta: {
                    allowed: [0,1]
                }
            },
            {
                path: '/add-funnel-family/:id',
                name: 'iframe-edit-funnel-family',
                component: () => import('@/views/iframe-form/form-components/funnel-family'),
                meta: {
                    allowed: [0,1]
                }
            },
            {
                path: '/add-niche/:id',
                name: 'iframe-edit-niche',
                component: () => import('@/views/iframe-form/form-components/niche'),
                meta: {
                    allowed: [0,1]
                }
            },
            {
                path: '/edit-control-group/:id',
                name: 'iframe-edit-control-group',
                component: () => import('@/views/iframe-form/form-components/control-group'),
                meta: {
                    allowed: [0,1]
                }
            },
            {
                path: '/edit-brand/:id',
                name: 'iframe-edit-brand',
                component: () => import('@/views/iframe-form/form-components/brand'),
                meta: {
                    allowed: [0,1]
                }
            },
            {
                path: '/edit-integration/:id',
                name: 'iframe-edit-integration',
                component: () => import('@/views/iframe-form/form-components/integration'),
                meta: {
                    allowed: [0,1]
                }
            },
            {
                path: '/edit-spreadsheet/:id',
                name: 'iframe-edit-spreadsheet',
                component: () => import('@/views/iframe-form/form-components/spreadsheet'),
                meta: {
                    allowed: [0,1]
                }
            },
            {
                path: '/edit-campaign/:id',
                name: 'iframe-edit-campaign',
                component: () => import('@/views/iframe-form/form-components/campaign'),
                meta: {
                    allowed: [0,1]
                }
            },

            // Add entities
            {
                path: '/add-funnel',
                name: 'iframe-add-funnel',
                component: () => import('@/views/iframe-form/form-components/funnel'),
                meta: {
                    allowed: [0,1]
                }
            },
            {
                path: '/add-funnel-family',
                name: 'iframe-add-funnel-family',
                component: () => import('@/views/iframe-form/form-components/funnel-family'),
                meta: {
                    allowed: [0,1]
                }
            },
            {
                path: '/add-niche',
                name: 'iframe-add-niche',
                component: () => import('@/views/iframe-form/form-components/niche'),
                meta: {
                    allowed: [0,1]
                }
            },
            {
                path: '/add-control-group',
                name: 'iframe-add-control-group',
                component: () => import('@/views/iframe-form/form-components/control-group'),
                meta: {
                    allowed: [0,1]
                }
            },
            {
                path: '/add-brand',
                name: 'iframe-add-brand',
                component: () => import('@/views/iframe-form/form-components/brand'),
                meta: {
                    allowed: [0,1]
                }
            },
            {
                path: '/add-integration',
                name: 'iframe-add-integration',
                component: () => import('@/views/iframe-form/form-components/integration'),
                meta: {
                    allowed: [0,1]
                }
            },
            {
                path: '/add-spreadsheet',
                name: 'iframe-add-spreadsheet',
                component: () => import('@/views/iframe-form/form-components/spreadsheet'),
                meta: {
                    allowed: [0,1]
                }
            },
            {
                path: '/add-campaign',
                name: 'iframe-add-campaign',
                component: () => import('@/views/iframe-form/form-components/campaign'),
                meta: {
                    allowed: [0,1]
                }
            },
        ]),
        ...withPrefix('/settings', [
            {
                path: '/settings',
                name: 'settings',
                component: () => import('@/views/settings/index'),
                meta: {
                    title: 'Settings',
                    allowed: [0,1]
                }
            },
        ]),
        ...withPrefix('/budgets', [
            {
                path: '/budgets',
                name: 'budgets',
                component: () => import('@/views/budgets/index'),
                meta: {
                    title: 'Budgets',
                    allowed: [0,1]
                }
            },
        ]),
        {
            path: '/about',
            name: 'about',
            component: () => import('../views/About'),
            meta: {
                title: 'About',
                // allowed: [0,1,2,3]
            }
        },
        ...withPrefix('/balance', [
            {
                path: '/report',
                name: 'balance-report',
                component: () => import('@/views/balance/report'),
                meta: {
                    title: 'Report',
                    allowed: [0,1]
                }
            },
        ]),
        {
            path: '/404',
            name: '404',
            component: () => import('../views/404')
        },
        {
            path: '/login',
            name: 'login',
            component: () => import('../views/Login')
        },
        {path: '*', redirect: '/404', hidden: true}
    ]
})
